import React, { ReactElement } from "react";
import Achivement from "../../Achivement-banner";
import FAQs from "../../hoc/faqs";
import Features from "../../hoc/features";
import HCSec2 from "../../hoc/hc-sec-2";
import HCSec3 from "../../hoc/hc-sec-3";
import HCSec5 from "../../hoc/hc-sec-5";
import Hero from "../../hoc/hero";
import LegalInfo from "../../hoc/legal-info";
import "./index.css";


export interface HomeContainerProps { }


export default function HomeContainer(
  props: HomeContainerProps
): ReactElement | null {

  return (
    <>
      <Hero />
      {/* <div id="apply-now" className="py-16 text-center">
        <Heading position="center">
          <h1 className="pb-2">Apply in seconds...</h1>
        </Heading>
        <ApplicationContainer />
      </div> */}
      <div >
        <HCSec2 />
      </div>
      <div>
        <HCSec3 />
      </div>
      <div >
        <Features />
      </div>
      <div id="why-us" className="my-16">
        <HCSec5 />
      </div>
      <div>
        {/* <Testimonials /> */}
      </div>
      <div id="legal-info">
        <LegalInfo />
      </div>
      <div className="mb-16">
        <FAQs />
      </div>
    </>
  )
}
