import React, { ReactElement } from "react";

export interface FeaturesProps { }

export default function HCSec2(props: FeaturesProps): ReactElement | null {

  return (
    <div
      className="bg-red-400 -mt-1 bg-center"
      style={{
        backgroundImage: `url(${require("../../images/sec-2-bg.png").default})`
      }}
    >
      <div
        className="-max-w-1366 mx-auto p-8 mb-16 text-white md:py-16 "
      >
        <div className="md:w-2/3 mx-auto text-center">
          <h3>
            We are the fastest lenders who deliver your requested funds within
            same day! We do the same for thousands of customers every single day!
          </h3>
        </div>
        <div
          style={{
            backgroundColor: "#E9A46C"
          }}
          className="mt-8 md:w-2/3 mx-auto text-center  p-8 rounded-xl shadow-lg">
          <div>
            <strong>Representative Example:</strong> $1,000 loan over a 12-month term would have a
            total cost, including interest, a total payback amount of $1,134.72. APR
            29.82%. Rates between 5.99% APR and 35.99% APR.Loan term lengths from 3 to
            36 months for qualified consumers.
          </div>
        </div>
      </div>
    </div>
  );
}
