import React, { ReactElement } from "react";
import SlickSlider from "../../components/slick";
import Featuer1Icon from "../../images/coins.svg";
import Featuer2Icon from "../../images/clock.svg";
import Featuer3Icon from "../../images/wallet.svg";
import Featuer4Icon from "../../images/online-payments.svg";
import Button from "../../components/button";
import Utils from "../../utils";
import { Link } from "gatsby";

interface IFeature {
  icon: any;
  title: string;
  description: string;
  bg?: string;
}
export interface FeaturesProps { }

export default function Features(props: FeaturesProps): ReactElement | null {
  const features: IFeature[] = [
    {
      icon: Featuer1Icon,
      title: "Loans For Everyone",
      description: `Loans For You’re welcome no matter what your credit score is. You can use the funds for any purpose..`,
      bg: "bg-red-100"
    },
    {
      icon: Featuer2Icon,
      title: "You’re In A Rush!",
      description: `We value your time greatly.We will take your application and process it quickly.You could have your funds in as little as 15 mins.`,
      bg: "bg-yellow-100"
    },
    {
      icon: Featuer3Icon,
      title: "Direct Deposit",
      description: `No waiting around or lining up. You can have your funds deposited direct to your bank account.`,
      bg: "bg-yellow-100"
    },
    {
      icon: Featuer4Icon,
      title: "100% Paperless and Online",
      description: `Loans are no longer a tedious job with us. No tedious paperwork required. You can get credit approval online.`,
      bg: "bg-gray-100"
    },

  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  return (
    <>
      <div className="bg-gray-800 text-white"  >
        <div className="-max-w-1366 mx-auto p-10"  >
          <SlickSlider settings={settings}>
            {features.map((feature, index) => (
              <div key={index}
                className="flex justify-center items-center p-8 "
              >
                <div className={"mb-4   "}>
                  <div className={"p-4  text-sm flex flex-col items-center text-center font-bold "}>
                    <img src={feature.icon} className={"w-24 h-24 "} />
                    <div className={"w-full pt-4 "}>
                      <h5 >
                        {feature.title}
                      </h5>
                    </div>
                  </div>
                  <div className="">
                    <p className="text-center px-2">
                      {feature.description}
                    </p>
                  </div>
                </div>

              </div>
            ))}
          </SlickSlider>
          <div className="flex justify-center mt-12">
            <Link to="/apply">
              <Button title={"APPLY NOW"}></Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
