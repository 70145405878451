import { Link } from "gatsby";
import React, { ReactElement } from "react";
import heroGfx from "../../images/hero-gfx.png";
import logoGfx from "../../images/logo-gfx.svg";
import FilledButton from "../filled-button";
const styles = require('./index.module.css')
export interface HeroProps { }

export default function Hero(props: HeroProps): ReactElement | null {
  const data = [
    {
      title: "We welcome all FICO Scores"
    },
    {
      title: "Quick Loans upto $50,000"
    },
    {
      title: "Borrow from 3 to 84 months"
    },
    {
      title: "Zero upfront fees or costs"
    },
  ]
  return (
    <div
      className="pb-16 md:pb-0 min-h-screen w-full overflow-hidden  bg-bottom bg-contain relative bg-no-repeat   "
      style={{
        backgroundImage: `url(${require("../../images/hero-bg.svg").default})`,
      }}
    >
      <div
        style={{
          // background: `linear-gradient(90deg, rgba(0,51,107,1) 0%, rgba(0,0,0,0) 100%);`,
          // background: "linear-gradient(90deg, rgba(51,50,0,0.1) 0%, rgba(0,0,0,0) 100%)",
        }}
        className="absolute  z-10 w-full h-full"
      />
      <div
        className="pt-8 md:pt-0 flex bg-center bg-cover flex-col -max-w-1366 md:flex-row   mx-auto px-8 ">
        <div className="relative flex-1 flex justify-center  mx-auto ">
          <div className="relative text-black z-20 flex flex-col justify-center  md:p-0 ">
            {/* <div className="opacity-0 flex flex-row justify-center md:justify-start">
              <img src={require("../../images/logo.svg").default} />
            </div> */}
            <div className=" flex flex-col items-center">
              <div className="text-center flex flex-col items-center ">
                <h5 className="pb-4">We Are</h5>
                <div className="flex items-center">
                  <h1 className="text-2xl md:text-4xl pr-2">LENDING</h1>
                  <img src={logoGfx} />
                  <h1 className="text-2xl md:text-4xl pl-2">GROUND</h1>
                </div>
                <div className="md:text-2xl pt-4">We offer <strong>online personal loans</strong>, processed within same day</div>
              </div>
              <div className={`-mt-8 ${styles.herofg}`}>
                <img  src={heroGfx} />
              </div>
              <div className="flex flex-col items-center -mt-2 text-center">
                {/* <Button onClick={() => { Utils.Commons.scrollTo("apply-now") }} className="mt-8" title="APPLY NOW"></Button> */}
                <strong className="mb-4">Let’s Get You Connected To A Direct Lender Who Can Deliver Funds Today!</strong>
                <Link to="/apply">
                  <FilledButton>
                    REQUEST FUNDS NOW
                  </FilledButton>
                </Link>
              </div>
              <ul className="mt-12 flex w-full md:w-3/5 flex-wrap" >
                {data.map((item, index) => <li className="w-full md:w-1/2 mb-1 flex justify-center font-bold" key={index}>
                  <img className="w-4 h-4 mt-1" src={require("../../images/cog.svg").default} />
                  <span className="pl-2">{item.title}</span>
                </li>)}
              </ul>

            </div>
          </div>
        </div>
        {/* <div
          className="flex-1 items-center relative z-10  bg-center flex">
          <div className="bg-white w-full rounded-lg shadow-lg">
            <EMICalculator hideButton />
          </div>
        </div> */}
      </div>
    </div >
  );
}
