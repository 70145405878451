import React, { ReactElement } from "react";
import sec3gfx1 from "../../images/sec-3-gfx-1.png";
import sec3gfx2 from "../../images/sec-3-gfx-2.png";
import cogIcon from "../../images/cog.svg";

export interface FeaturesListProps { }

export default function HCSec3(props: FeaturesListProps): ReactElement | null {

  const steps = [
    {
      title: "We need to know a little about you",
      desc: "Complete the 2min online application with your basic information. We will search our highly specialized lending panel for a good match.Your personal data is safe with us."
    },
    {
      title: "Get Approved Within minutes",
      desc: "Review the offer presented. Check the loan value, the period you have to pay back the loan and the APR terms.If the loan suits your needs, you can accept and have the funds deposited quickly."
    },
    {
      title: "Receive The Cash Loan",
      desc: "Once approved, you can finish up your acceptance with the lender and have your funds deposited directly to your bank account."
    },
  ]

  const feats = [
    "Simple loan quote of upto $50,000",
    "Paid directly to your bank account fast",
    "All credit histories considered",
    "Quick, secure, and hassle-free online form"
  ]

  return (
    <>

      <div className="-max-w-1366 mx-auto p-8  w-full  my-32">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img className="w-72 md:w-auto" src={sec3gfx1} />
          </div>
          <div className="w-full md:w-1/2  flex justify-center items-center">
            <div>
              {feats.map((feat, i) => (
                <div key={i} className="flex items-start my-4 font-bold">
                  <img className="pr-2" src={cogIcon} />
                  <span>
                    {feat}
                  </span>
                </div>
              ))
              }
            </div>
          </div>
        </div>
        
        <div className="mt-16 md:mt-0 flex flex-col md:flex-row-reverse items-center justify-center">
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img className="w-72 md:w-auto" src={sec3gfx2} />
          </div>
          <div className="w-full md:w-1/2  flex justify-center items-center">
            <div className="md:w-2/3">
              {steps.map((step, i) => (
                <div key={i} className="flex flex-col items-start my-8 font-bold">
                  <span className="text-xs text-bold">STEP {i+1}</span>
                  <h4 className="text-red-500">
                    {step.title}
                  </h4>
                  <h6 className="text-gray-600">
                    {step.desc}
                  </h6>
                </div>
              ))
              }
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
}
